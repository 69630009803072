<!-- 关于Hyve：企业责任 -->
<template>
<div>
    <div v-if="config.img&&config.img.isShow">
        <TopImg :web='config.img.pc' :wap='config.img.mobile'></TopImg>
    </div>
    <section class="wCenter">
        <div class="tabs">
            <span @click="current='responsibility'" :class="{selected:current=='responsibility'}" class="tab">企业责任</span>
            <span @click="current='regulations'" :class="{selected:current=='regulations'}" class="tab">反腐条例</span>
        </div>
    </section>
    <!-- 企业责任 -->
    <div v-if="current=='responsibility'">
        <Responsibility></Responsibility>
    </div>
    <!-- 反腐条例 -->
    <div v-if="current=='regulations'">
        <Regulations></Regulations>
    </div>
</div>
</template>

<script>
import {
    getPageConfigByCode,
} from '@/api/api'
import TopImg from '@/components/topImg'
import Responsibility from "./Responsibility"
import Regulations from "./Regulations"
export default {
    components: {
        TopImg,
        Responsibility,
        Regulations
    },
    data() {
        return {
            current: 'responsibility',
            config: {}
        }
    },
    mounted() {
        this.getPageConfigByCode()
    },
    methods: {
        // 页面配置
        getPageConfigByCode() {
            const params = {
                code: 'responsibility/index',
            }
            getPageConfigByCode(params).then(res => {
                if (res.success) {
                    this.config = JSON.parse(res.result)
                }
            })
        },
    },
}
</script>

<style lang="scss">
.tabs {
    $color: #ff1932;
    .tab {
        font-size: .3rem;
        padding: .1rem;
        margin: .2rem;
        cursor: pointer;
        display: inline-block;
        letter-spacing: .02rem;
        color: #222;
		@media (max-width: 767px) {
		font-size: 0.4rem;
		 
		 }
		  
        &:first-child {
            margin-left: 0;
        }

        &.selected {
            color: $color;
            border-bottom: $color solid 2px;
        }
    }

}
</style>
